import { useContext } from "react";
import { io } from "socket.io-client";
import { API_ADDRESS } from "./variants";
import axios from "axios";
import { AuthContext } from "../context/Context";
class MessageFunction {
    socket;
    context = AuthContext
    authContext;
    constructor() {
        this.authContext = useContext(this.context)
        this.socket = io(API_ADDRESS, {
            withCredentials: true,
            extraHeaders: {
                'Authorization': this.authContext.access_token
            }
        });
    }
    async getMessages() {
        let messages = await axios({
            method: 'get',
            url: `${API_ADDRESS}/message/get-messages`,
            headers: {
                Authorization: this.authContext.access_token
            }
        })
        return messages
    }
    async sendMessage(message) {
        this.socket.emit('sendMessage', {
            userId: this.authContext.userId,
            messageContent: message,
            messageType: 'text'
        })
    }
    async sendFile(file) {
        this.socket.emit('sendMessage', {
            userId: this.authContext.userId,
            messageContent: JSON.stringify(file),
            messageType: 'file'
        })
    }
}
export default MessageFunction