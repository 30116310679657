import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './auth.css'
import UserFunction from '../../lib/UserFunction';
const RegisterPage = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [registerWait, setRegisterWait] = useState(false);
    const userFunction = new UserFunction()
    const register = async () => {
        if (username.match(/.*\S.*/) == null || password.match(/.*\S.*/) == null || rePassword.match(/.*\S.*/) == null) {
            toast.error("Username or password or confirm password is missing!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        } else if (username.match(/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]/) == null) {
            toast.error("Username is invalid! 8-20 characters!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        } else if (password !== rePassword) {
            toast.error("Password and confirm password not match!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        } else try {
            setRegisterWait(true)
            await userFunction.register(username, password)
                .then((data) => {
                    if (data.data.success) {
                        toast.success(data.data.message, {
                            position: toast.POSITION.TOP_LEFT,
                            autoClose: 3000
                        });
                        setUsername('')
                        setPassword('')
                        setRePassword('')
                        setTimeout(() => {
                            navigate('/login');
                        }, 3000)
                    } else {
                        toast.error(data.data.message, {
                            position: toast.POSITION.TOP_LEFT,
                            autoClose: 3000
                        });
                    }
                    setRegisterWait(false);
                })
                .catch((err) => {

                })
        }
        catch (err) {
            toast.error("An error occurred while register!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        }
    }
    return (
        <div className="auth">
            <ToastContainer />
            <Row className="h-100">
                <Col className="col-lg-5 col-12">
                    <div className="auth-left">
                        <h1 className="auth-title">Register</h1>
                        <p className="auth-subtitle mb-5">Input your data to register to my website.</p>
                        <Form action="#">
                            <Form.Group className="form-group position-relative has-icon-left mb-4">
                                <Form.Control
                                    type="text"
                                    className="form-control-xl"
                                    placeholder="Username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    required />
                                <div className="form-control-icon">
                                    <i className="bi bi-person"></i>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-group position-relative has-icon-left mb-4">
                                <Form.Control
                                    type="password"
                                    className="form-control-xl"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required />
                                <div className="form-control-icon">
                                    <i className="bi bi-shield-lock"></i>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-group position-relative has-icon-left mb-4">
                                <Form.Control
                                    type="password"
                                    className="form-control-xl"
                                    placeholder="Confirm Password"
                                    value={rePassword}
                                    onChange={e => setRePassword(e.target.value)}
                                    required />
                                <div className="form-control-icon">
                                    <i className="bi bi-shield-lock"></i>
                                </div>
                            </Form.Group>
                            <Button disabled={registerWait ? true : false} className="btn btn-primary btn-block btn-lg shadow-lg mt-5" onClick={register}>
                                {
                                    (registerWait) ?
                                        <div className="spinner-border" role="status">
                                        </div>
                                        :
                                        <>
                                        </>
                                }
                                Sign Up
                            </Button>
                        </Form>
                        <div className="text-center mt-5 text-lg fs-4">
                            <p className='text-gray-600'>Already have an account?
                                <Link to="/login" className="auth-font-bold">Login</Link>.</p>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-7 d-none d-lg-block">
                    <div className="auth-right">
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default RegisterPage;