import './chat.css';
import Message from './Message';
import React, { useState, useRef, useEffect, useContext } from 'react';
import MenuBar from '../menu-bar/MenuBar';
import { AuthContext } from '../../context/Context';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { API_ADDRESS } from '../../lib/variants';
import { ToastContainer, toast } from 'react-toastify';
import io from 'socket.io-client';
import FileMessageAttach from './FileMessageAttach';
import MessageFunction from '../../lib/MessageFunction';
const ChatPage = () => {
  const authContext = useContext(AuthContext)
  const socket = io(API_ADDRESS, {
    withCredentials: true,
    extraHeaders: {
      'Authorization': authContext.access_token
    }
  });
  const messageFunction = new MessageFunction()
  const [isNeedToGetMessages, setIsNeedToGetMessages] = useState(true)
  const [showFileMessageAttach, setShowFileMessageAttach] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null)
  const [messageList, setMessageList] = useState([]);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    getMessages();
  }, [isNeedToGetMessages])
  socket.on('receiveMessage', (e) => {
    setIsNeedToGetMessages(true)
  });
  const getMessages = async () => {
    await messageFunction.getMessages()
      .then((data) => {
        if (data.data.success) {
          let temp_MessageList = [];
          data.data.message.forEach(e => {
            temp_MessageList.push(
              {
                key: e._id,
                messageContent: e.messageContent,
                messageTime: e.messageDatetime,
                messageType: e.messageType,
                username: e.userId.username,
                isSendMessage: e.userId._id === authContext.userId ? true : false
              },
            )
          });
          setMessageList(temp_MessageList)
          setIsNeedToGetMessages(false)
        } else {
          toast.error(data.data.message, {
            position: toast.POSITION.TOP_LEFT,
            autoClose: 3000
          });
        }
      })
      .catch((err) => {
        toast.error("Error while get messages!", {
          position: toast.POSITION.TOP_LEFT,
          autoClose: 3000
        });
      })
  }
  const sendMessage = async () => {
    if (inputMessage.match(/.*\S.*/) != null) {
      messageFunction.sendMessage(inputMessage)
      setInputMessage('');
    }
  }
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  }
  useEffect(() => {
    scrollToBottom()
  }, [messageList]);

  return (
    <>
      <ToastContainer />
      <MenuBar />
      <div className="main">
        <header className="mb-3">
          <Link to="#" className="burger-btn d-block d-xl-none" onClick={() => authContext.setShowSideBar(true)}>
            <i className="bi bi-justify fs-3"></i>
          </Link>
        </header>
        <div className="page-heading">
          <div className="page-title">
            <Row>
              <div className="col-12 col-md-6 order-md-1 order-last">
                <h3>Chat Feature</h3>
              </div>
            </Row>
          </div>
          <FileMessageAttach
            props={{
              showFileMessageAttach,
              setShowFileMessageAttach
            }}
          />
          <section className="section">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <div className="media d-flex align-items-center">
                      <div className="avatar me-3">
                        <img src="https://cdn.meiker.io/assets/184907/2022/05/icon_20220522143235628a4983da383.png" alt="" />
                        <span className="avatar-status bg-success"></span>
                      </div>
                      <div className="name flex-grow-1">
                        <h6 className="mb-0">Chat B1906810</h6>
                        <span className="text-xs">Online</span>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="pt-4 bg-grey">
                    <div className="chat-content">
                      {
                        messageList.map((e) => {
                          return <Message key={e.key} props={
                            {
                              messageContent: e.messageContent,
                              messageTime: e.messageTime,
                              messageType: e.messageType,
                              isSendMessage: e.isSendMessage,
                              username: e.username,
                            }
                          } />
                        })
                      }
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <div className="message-form d-flex flex-direction-column align-items-center">
                      <div className="input-group mb-3">
                        <Button onClick={() => setShowFileMessageAttach(true)}>
                          <dt className="the-icon"><svg className="svg-inline--fa fa-file-export fa-w-18 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-export" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128zM571 308l-95.7-96.4c-10.1-10.1-27.4-3-27.4 11.3V288h-64v64h64v65.2c0 14.3 17.3 21.4 27.4 11.3L571 332c6.6-6.6 6.6-17.4 0-24zm-379 28v-32c0-8.8 7.2-16 16-16h176V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V352H208c-8.8 0-16-7.2-16-16z"></path></svg></dt>
                        </Button>
                        <Form.Control autoFocus value={inputMessage} maxLength={2000} onKeyUp={handleKeyUp} onChange={(e) => setInputMessage(e.target.value)} type="text" placeholder="Type your message.." />
                      </div>
                    </div>
                  </Card.Footer>
                  <div ref={messagesEndRef}></div>
                </Card>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </>
  );
}

export default ChatPage;
