import React from 'react'
import DataTable from "react-data-table-component";
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';
import './File.css'
import FileFunction from '../../lib/FileFunction';
const FileManager = ({ props }) => {
    const fileFunction = new FileFunction()
    const columns = [
        {
            name: 'File name',
            selector: row => row.fileName,
            width: "62%",
        },
        {
            name: 'File type',
            selector: row => row.fileType,
            center: true,
            width: "12%",
        },
        {
            name: 'File size',
            selector: row => row.fileSize,
            center: true,
            width: "12%",
        },
        {
            name: 'File action',
            selector: row => row.fileAction,
            center: true,
            width: "14%"
        },
    ];
    let data = [
    ]
    data = [];
    Array.from(props.files).forEach(e => {
        data.push(
            {
                id: e._id,
                fileName: <Link to={`./file/${e.fileId}`}>{e.fileName}</Link>,
                fileType: e.fileType.replace('application/', ''),
                fileSize: fileFunction.formatBytes(e.fileSize),
                fileAction:
                    <>
                        <div className="btn-group btn-group-sm" role="group">
                            <Button disabled={props.isDownloading === e.fileId ? true : false} className="btn btn-primary" onClick={() => props.downloadFile(e.fileId, e.fileName, e.fileCloud)}>
                                {
                                    (props.isDownloading === e.fileId) ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        :
                                        <>
                                            <dt className="the-icon">
                                                <svg className="svg-inline--fa fa-cloud-download-alt fa-w-20 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-download-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"></path>
                                                </svg>
                                            </dt>
                                        </>
                                }

                            </Button>
                            <Button disabled={props.isEditting === e.fileId ? true : false} className="btn btn-success" onClick={() => props.editFile(e.fileId, e.fileName, e.fileCloud)}>
                                {
                                    (props.isEditting === e.fileId) ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        :
                                        <>
                                            <dt className="the-icon">
                                                <svg className="svg-inline--fa fa-edit fa-w-18 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                            </dt>
                                        </>
                                }
                            </Button>
                            <Button disabled={props.isDeleting === e.fileId ? true : false} className="btn btn-danger" onClick={() => props.deleteFile(e.fileId, e.fileName, e.fileCloud)}>
                                {
                                    (props.isDeleting === e.fileId) ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        :
                                        <>
                                            <dt className="the-icon">
                                                <svg className="svg-inline--fa fa-trash-alt fa-w-14 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
                                                </svg>
                                            </dt>
                                        </>
                                }
                            </Button>
                        </div>
                    </>,
            }
        )
    });
    return (
        <>
            <DataTable
                title="File storing managerment"
                columns={columns}
                data={data}
                direction="auto"
                fixedHeader
                highlightOnHover
                noContextMenu
                noHeader
                pagination
                persistTableHead
                pointerOnHover
                responsive
                striped
                subHeaderAlign="center"
                subHeaderWrap
            />
        </>
    )
}
export default FileManager;