import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../../context/Context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './auth.css'
import UserFunction from '../../lib/UserFunction';
const LoginPage = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginWait, setLoginWait] = useState(false);
    const userFunction = new UserFunction()
    const login = async () => {
        if (username.match(/.*\S.*/) == null || password.match(/.*\S.*/) == null) {
            toast.error("Username or password is missing!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        } else if (username.match(/^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]/) == null) {
            toast.error("Username is invalid!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000
            });
        } else
            try {
                setLoginWait(true)
                await userFunction.login(username, password)
                    .then((data) => {
                        if (data.data.success) {
                            toast.success(data.data.message, {
                                position: toast.POSITION.TOP_LEFT,
                                autoClose: 3000
                            });
                            authContext.setLogin(
                                true,
                                data.data.access_token,
                                data.data.userId,
                                data.data.username,
                                data.data.userimage
                            )
                            setUsername('')
                            setPassword('')
                            setTimeout(() => {
                                navigate('/');
                            }, 3000)
                        } else {
                            toast.error(data.data.message, {
                                position: toast.POSITION.TOP_LEFT,
                                autoClose: 3000
                            });
                        }
                        setLoginWait(false)
                    })
                    .catch((err) => {

                    })
            }
            catch (err) {
                toast.error("An error occurred while login!", {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: 3000
                });
            }

    }
    return (
        <div className="auth">
            <ToastContainer />
            <Row className="h-100">
                <Col className="col-lg-5 col-12">
                    <div className="auth-left">
                        <h1 className="auth-title">Log in.</h1>
                        <p className="auth-subtitle mb-5">Log in with your data that you entered during registration.</p>
                        <Form action='#'>
                            <Form.Group className="form-group position-relative has-icon-left mb-4">
                                <Form.Control
                                    type="text"
                                    className="form-control-xl"
                                    placeholder="Username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    required />
                                <div className="form-control-icon">
                                    <i className="bi bi-person"></i>
                                </div>
                            </Form.Group>
                            <Form.Group className="form-group position-relative has-icon-left mb-4">
                                <Form.Control
                                    type="password"
                                    className="form-control-xl"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required />
                                <div className="form-control-icon">
                                    <i className="bi bi-shield-lock"></i>
                                </div>
                            </Form.Group>
                            <Button disabled={loginWait ? true : false} className="btn btn-primary btn-block btn-lg shadow-lg mt-5" onClick={() => login()}>
                                {
                                    (loginWait) ?
                                        <div className="spinner-border" role="status">
                                        </div>
                                        :
                                        <>
                                        </>
                                }
                                Log in
                            </Button>
                        </Form>
                        <div className="text-center mt-5 text-lg fs-4">
                            <p className="text-gray-600">Don't have an account?
                                <Link to='/register' className="auth-font-bold">Sign up</Link>.</p>
                        </div>
                    </div>
                </Col>
                <Col className="col-lg-7 d-none d-lg-block">
                    <div className="auth-right">
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default LoginPage;