import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../context/Context';
import ChatPage from '../component/chat/ChatPage';
import RegisterPage from '../component/auth/RegisterPage';
import LoginPage from '../component/auth/LoginPage';
import ProfilePage from '../component/profile/ProfilePage';
import HomePage from '../component/home/HomePage';
import FileUploaderPage from '../component/file-storage/FileUploaderPage';
import FilePage from '../component/file-storage/FilePage';
export const RouteController = () => {
    const authContext = useContext(AuthContext);
    return (
        <Router>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/home' element={<HomePage />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path="/file-storing/file/:fileId" element={<FilePage />} />
                <Route exact path="/file-storing/file/" element={<Navigate to="/file-storing" />} />
                <Route exact path="/logout" element={<Navigate to="/" />} />
                <Route exact path="/profile" element={authContext.isLoggedIn ? <ProfilePage /> : <Navigate to="/login" />} />
                <Route exact path="/chat" element={authContext.isLoggedIn ? <ChatPage /> : <Navigate to="/" />} />
                <Route exact path="/file-storing" element={authContext.isLoggedIn ? <FileUploaderPage /> : <Navigate to="/" />} />
                {/* <Route exact path="/file-storing/file/:fileName" element={authContext.isLoggedIn ? <FilePage /> : <Navigate to="/" />} /> */}
            </Routes>
        </Router>

    )
}