import React, { useContext, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MenuBar from '../menu-bar/MenuBar';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../context/Context';
import { useLocation } from 'react-router-dom';
import { ROOT_URL } from '../../lib/variants';
import FileFunction from '../../lib/FileFunction';
const FilePage = () => {
    const fileFunction = new FileFunction();
    const location = useLocation().pathname;
    // const fileId = location.split('/')[location.split('/').length - 1]
    const [fileDetail, setFileDetail] = useState({ fileId: undefined, fileName: undefined, fileSize: undefined, fileType: undefined, fileOwner: undefined, fileCloud: undefined });
    const [isGotFileDetail, setIsGotFileDetail] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const authContext = useContext(AuthContext);
    useEffect(() => {
        getFileDetail(location)
    }, [isGotFileDetail])
    const getFileDetail = async (location) => {
        try {
            await fileFunction.getFileDetail(location)
                .then((data) => {
                    setIsGotFileDetail(true)
                    if (data.data.success)
                        setFileDetail({
                            fileId: data.data.message.fileId,
                            fileName: data.data.message.fileName,
                            fileSize: data.data.message.fileSize,
                            fileType: data.data.message.fileType,
                            fileOwner: data.data.message.userId.username,
                            fileCloud: data.data.message.fileCloud,
                            fileUrl: data.data.message.fileUrl
                        })
                })
        } catch (err) {
            toast.error('An error occurred while getting file, please try again!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
    }
    const downloadFile = async (fileId, fileName, fileCloud) => {
        try {
            setIsDownloading(fileId)
            await fileFunction.downloadFile(fileId, fileName, fileCloud)
                .then(async (data) => {
                    setIsDownloading(false)
                    if (data.data.success) {
                        toast.success(data.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                        window.open(data.data.url, '_blank')
                    }
                    else
                        toast.error(data.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                })
        } catch (err) {
            toast.error('An error occurred while deleting file, please try again!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }

    }
    const copyLink = () => {
        navigator.clipboard.writeText(`${ROOT_URL}${location}`);
        toast.success('Copied link to clipboard!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
        });
    }
    return (
        <>
            <MenuBar />
            <ToastContainer />
            <div className="main">
                <header className="mb-3">
                    <Link to="#" className="burger-btn d-block d-xl-none" onClick={() => authContext.setShowSideBar(true)}>
                        <i className="bi bi-justify fs-3"></i>
                    </Link>
                </header>
                <div className="page-heading">
                    <div className="page-title">
                        <Row>
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>File Sharing</h3>
                            </div>
                        </Row>
                    </div>

                    <section className="section">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            fileDetail.fileId === undefined ?
                                                <>
                                                    <div className="alert alert-danger">
                                                        <h4 className="alert-heading">File is not exits!</h4>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="alert alert-primary">
                                                        <h4 className="alert-heading">File name</h4>
                                                        <p>{fileDetail.fileName}</p>
                                                    </div>
                                                    <div className="alert alert-secondary">
                                                        <h4 className="alert-heading">File type</h4>
                                                        <p>{fileDetail.fileType}</p>
                                                    </div>
                                                    <div className="alert alert-dark">
                                                        <h4 className="alert-heading">File preview</h4>
                                                        <embed width='100%' height='500px' src={fileDetail.fileUrl} />
                                                    </div>
                                                    <div className="alert alert-success">
                                                        <h4 className="alert-heading">File size</h4>
                                                        <p>{fileFunction.formatBytes(fileDetail.fileSize)}</p>
                                                    </div>
                                                    <div className="alert alert-danger">
                                                        <h4 className="alert-heading">File Owner</h4>
                                                        <p>{fileDetail.fileOwner}</p>
                                                    </div>
                                                    <div className="alert alert-warning">
                                                        <h4 className="alert-heading">File Cloud</h4>
                                                        <p>{fileDetail.fileCloud}</p>
                                                    </div>
                                                    <div className="alert alert-info">
                                                        <h4 className="alert-heading">Action</h4>
                                                        <div className="buttons">
                                                            <Button disabled={isDownloading === fileDetail.fileId ? true : false} className="btn btn-primary" onClick={() => downloadFile(fileDetail.fileId, fileDetail.fileName, fileDetail.fileCloud)}>
                                                                {
                                                                    (isDownloading === fileDetail.fileId) ?
                                                                        <div className="spinner-border spinner-border-sm" role="status">
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <dt className="the-icon">
                                                                                <svg className="svg-inline--fa fa-cloud-download-alt fa-w-20 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-download-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"></path>
                                                                                </svg>
                                                                            </dt>
                                                                        </>
                                                                }

                                                            </Button>
                                                            <Button className="btn btn-success" onClick={copyLink}>
                                                                <dt className="the-icon"><svg className="svg-inline--fa fa-copy fa-w-14 fa-fw select-all" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="copy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path></svg></dt>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default FilePage