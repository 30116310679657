import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../context/Context';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MenuBar from '../menu-bar/MenuBar';
import { ToastContainer, toast } from 'react-toastify';
import FileManager from './FileManager';
import Swal from 'sweetalert2';
import FileFunction from '../../lib/FileFunction';
const FileUploaderPage = () => {
  const fileFunction = new FileFunction();
  const authContext = useContext(AuthContext);
  const [fileSelected, setFileSelected] = useState(null);
  const [fileDetail, setFileDetail] = useState({ fileName: null, fileSize: null, fileType: undefined });
  const [cloudSelected, setCloudSelected] = useState('onedrive')
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [files, setFiles] = useState([]);
  const [getFile, setGetFile] = useState(false);
  useEffect(() => {
    getFiles()
  }, [getFile])
  const getFiles = async () => {
    await fileFunction.getFiles().then((data) => {
      if (data.data.success) {
        setFiles(data.data.message);
      }
      else
        toast.error(data.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
    })
  }

  const fileRead = (file) => {
    setFileSelected(file)
    if (file !== undefined) {
      setFileDetail({ fileName: file.name, fileSize: file.size, fileType: file.type })
    } else {
      setFileDetail({ fileName: null, fileSize: null, fileType: undefined })
    }
  }

  const deleteFile = async (fileId, fileName, fileCloud) => {
    Swal.fire({
      title: `Do you want to delete ${fileName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsDeleting(fileId)
          await fileFunction.deleteFile(fileId, fileName, fileCloud)
            .then((data) => {
              setIsDeleting(false)
              if (data.data.success) {
                setGetFile(!getFile)
                toast.success(data.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000
                });
              }
              else
                toast.error(data.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000
                });
            })
        } catch (err) {
          toast.error('An error occurred while deleting file, please try again!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
        }


      }
    })

  }

  const uploadFile = async () => {
    if (fileSelected == null) {
      toast.error('Please select a file to upload!', {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 3000
      });
    } else {
      setIsUploading(true)
      try {
        await fileFunction.uploadFile(fileSelected, fileDetail.fileName, cloudSelected)
          .then((data) => {
            setIsUploading(false)
            if (data.data.success) {
              setGetFile(!getFile)
              setFileSelected(null)
              setFileDetail({ fileName: null, fileSize: null, fileType: undefined })
              toast.success(data.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
            }
            else
              toast.error(data.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
              });
          })
      } catch (err) {
        toast.error('An error occurred while uploading, please try again!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      }

    }
  }

  const downloadFile = async (fileId, fileName, fileCloud) => {
    try {
      setIsDownloading(fileId)
      await fileFunction.downloadFile(fileId, fileName, fileCloud)
        .then(async (data) => {
          setIsDownloading(false)
          if (data.data.success) {
            toast.success(data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
            window.open(data.data.url, '_blank')
          }
          else
            toast.error(data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
        })
    } catch (err) {
      toast.error('An error occurred while deleting file, please try again!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
    }

  }

  const editFile = async (fileId, fileName, fileCloud) => {
    setIsEditting(fileId)
    try {
      await fileFunction.editFile(fileId, fileName, fileCloud).then((data) => {
        if (data != null)
          if (data.data.success) {
            setGetFile(!getFile)
            Swal.fire({ icon: 'success', text: data.data.message })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: data.data.message,
            })
          }
        setIsEditting(false)
      })
    } catch (err) {
      setIsEditting(false)
      toast.error('An error occurred while edtting file, please try again!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
    }
  }
  return (
    <>
      <MenuBar />
      <ToastContainer />
      <div className="main">
        <header className="mb-3">
          <Link to="#" className="burger-btn d-block d-xl-none" onClick={() => authContext.setShowSideBar(true)}>
            <i className="bi bi-justify fs-3"></i>
          </Link>
        </header>
        <div className="page-heading">
          <div className="page-title">
            <Row>
              <div className="col-12 col-md-6 order-md-1 order-last">
                <h3>File Uploader</h3>
              </div>
            </Row>
          </div>
          <section className="section">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row >
                      <Col md={12}>
                        <div className="mb3">
                          <div className="input-group input-group-lg">
                            <input className="form-control" type="file" onChange={(e) => fileRead(e.target.files[0])} />
                          </div>
                          <hr />
                          {
                            (fileDetail.fileName != null) ?
                              <>
                                <Badge bg="primary">File name: {fileDetail.fileName}</Badge><hr />
                                <Badge bg="success">File size: {fileFunction.formatBytes(fileDetail.fileSize)}</Badge><hr />
                                <Badge bg="info">File type: {fileDetail.fileType}</Badge><hr />
                                <div className="input-group mb-3">
                                  <label className="input-group-text">Cloud</label>
                                  <select className="form-select" onChange={(e) => { setCloudSelected(e.target.value) }}>
                                    <option value='onedrive'>OneDrive</option>
                                    <option value='googledrive'>Google Drive</option>
                                  </select>
                                  <Button disabled={isUploading ? true : false} className="btn-primary" onClick={uploadFile}>
                                    {
                                      (isUploading) ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        :
                                        <>
                                          Upload file
                                        </>
                                    }
                                  </Button>
                                </div>

                              </>
                              :
                              <></>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <h3>File Manager</h3>
                    <Row >
                      <Col md={12}>
                        <FileManager
                          props={{
                            files: files,
                            deleteFile: deleteFile,
                            isDeleting: isDeleting,
                            downloadFile: downloadFile,
                            isDownloading: isDownloading,
                            isEditting: isEditting,
                            editFile: editFile
                          }} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </>
  );
}

export default FileUploaderPage;
