import React, { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/Context";
import { useLocation } from "react-router-dom";
const MenuBar = () => {
    const location = useLocation();
    const authContext = useContext(AuthContext)
    const logout = () => {
        authContext.setLogin(false, null, null, null)
    }
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1200)
                authContext.setShowSideBar(false)
            else
                authContext.setShowSideBar(true)
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            <div id="sidebar" className={authContext.showSideBar ? "active" : ""}>
                <div className="sidebar-wrapper active ps ps--active-y">
                    <div className="sidebar-header">
                        <div className="d-flex justify-content-between">
                            <div className="toggler" onClick={() => authContext.setShowSideBar(false)}>
                                <Link to="#" className="sidebar-hide d-xl-none d-block"><i className="bi bi-x bi-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-menu">
                        <ul className="menu">
                            <li className={(location.pathname === '/' || location.pathname === '/home') ? "sidebar-item active" : "sidebar-item"}>
                                <Link to="/" href="index.html" className="sidebar-link">
                                    <i className="bi bi-grid-fill"></i>
                                    <span>Home</span>
                                </Link>
                            </li>
                            {
                                !authContext.isLoggedIn ?
                                    <>
                                        <li className="sidebar-item">
                                            <Link to="/register" className="sidebar-link">
                                                <i className="bi bi-pen-fill"></i>
                                                <span>Register</span>
                                            </Link>
                                        </li>
                                        <li className="sidebar-item">
                                            <Link to="/login" className="sidebar-link">
                                                <i className="icon-mid bi bi-person me-2"></i>
                                                <span>Login</span>
                                            </Link>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li className={location.pathname === '/profile' ? "sidebar-item active" : "sidebar-item"}>
                                            <Link to="/profile" className="sidebar-link">
                                                <i className="bi bi-person-badge-fill"></i>
                                                <span>@{authContext.username}</span>
                                            </Link>
                                        </li>
                                        <li className={location.pathname === '/chat' ? "sidebar-item active" : "sidebar-item"}>
                                            <Link to="/chat" className="sidebar-link">
                                                <i className="bi bi-chat-dots-fill"></i>
                                                <span>Chat Feature</span>
                                            </Link>
                                        </li>
                                        <li className={location.pathname === '/file-storing' ? "sidebar-item active" : "sidebar-item"}>
                                            <Link to="/file-storing" className="sidebar-link">
                                                <i className="bi bi-cloud-arrow-up-fill"></i>
                                                <span>File Storage</span>
                                            </Link>
                                        </li>
                                        <li className="sidebar-item">
                                            <Link to='#' onClick={() => logout()} className="sidebar-link">
                                                <i className="icon-mid bi bi-box-arrow-left me-2"></i>
                                                <span>Logout</span>
                                            </Link>
                                        </li>
                                    </>
                            }
                        </ul>
                    </div>
                    <button className="sidebar-toggler btn x"><i data-feather="x"></i></button>
                </div>
            </div>
        </>
    )
}
export default MenuBar;