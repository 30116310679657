import './App.css';
// import './bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap-icons.css';
// import './main.js';
import React, { useState } from 'react';
import { RouteController } from './route/RouteController'
import { AuthContext } from './context/Context';
import { ACCESS_TOKEN, USER_ID, USERNAME, USER_IMAGE } from './lib/variants';
import { useCookies } from 'react-cookie';
const App = () => {
  const [access_token, setAccessToken, removeAccessToken] = useCookies([ACCESS_TOKEN]);
  const [userId, setUserId, removeUserId] = useCookies([USER_ID]);
  const [username, setUsername, removeUsername] = useCookies([USERNAME]);
  const [userimage, setUserImage, removeUserImage] = useCookies([USER_IMAGE]);
  const [isLoggedIn, setLoggedIn] = useState(access_token.b1906810 != null ? true : false);
  const [sideBar, setSideBar] = useState(true);
  const setLogin = (state, access_token, userId, username, userimage) => {
    setLoggedIn(state)
    if (access_token != null)
      setAccessToken(ACCESS_TOKEN, access_token, { path: '/' })
    else
      removeAccessToken(ACCESS_TOKEN)
    if (userId != null)
      setUserId(USER_ID, userId, { path: '/' })
    else
      removeUserId(USER_ID)
    if (username != null)
      setUsername(USERNAME, username, { path: '/' })
    else
      removeUsername(USERNAME)
    if (userimage != null)
      setUserImage(USER_IMAGE, userimage, { path: '/' })
    else
      removeUserImage(USER_IMAGE)
  }
  const setShowSideBar = (state) => {
    setSideBar(state)
  }
  return (
    <AuthContext.Provider value={{ isLoggedIn: isLoggedIn, setLogin, showSideBar: sideBar, setShowSideBar, access_token: access_token.b1906810, userId: userId.id, username: username.user, userimage: userimage.image }}>
      <RouteController />
    </AuthContext.Provider>
  );
}

export default App;
