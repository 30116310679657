import { useContext } from "react";
import { API_ADDRESS } from "./variants";
import { AuthContext } from "../context/Context";
import axios from "axios"
class UserFunction {
    context = AuthContext
    authContext;
    constructor() {
        this.authContext = useContext(this.context)
    }
    async updateAvatar(imageSelected) {
        let update = await axios({
            method: 'put',
            data: { imageSelected },
            url: `${API_ADDRESS}/user/update-avatar`,
            headers: {
                Authorization: this.authContext.access_token,
                "Content-Type": "multipart/form-data"
            }
        })
        return update
    }
    async updatePassword(newPassword) {
        let update = await axios({
            method: 'put',
            data: { newPassword },
            url: `${API_ADDRESS}/user/update-password`,
            headers: {
                Authorization: this.authContext.access_token
            }
        })
        return update
    }
    async register(username, password) {
        let register = await axios.post(`${API_ADDRESS}/auth/register`, { username, password })
        return register
    }
    async login(username, password) {
        let login = await axios.post(`${API_ADDRESS}/auth/login`, { username, password })
        return login
    }
}
export default UserFunction