import axios from "axios"
import { useContext } from "react";
import { API_ADDRESS } from "./variants";
import { AuthContext } from "../context/Context";
import Swal from 'sweetalert2'
class FileFunction {
    context = AuthContext
    authContext;
    constructor() {
        this.authContext = useContext(this.context)
    }
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    async uploadFile(file, fileName, fileCloud) {
        let upload = await axios({
            method: 'post',
            url: `${API_ADDRESS}/file-storing/${fileCloud}/upload`,
            data: { file, fileName },
            headers: {
                Authorization: this.authContext.access_token,
                "Content-Type": "multipart/form-data"
            }
        })
        return upload;
    }
    async downloadFile(fileId, fileName, fileCloud) {
        let download = await axios({
            method: 'put',
            url: `${API_ADDRESS}/file-storing/${fileCloud}/download`,
            data: { fileId },
            headers: {
                Authorization: this.authContext.access_token,
            }
        })
        return download;
    }
    async deleteFile(fileId, fileName, fileCloud) {
        let remove = await axios({
            method: 'delete',
            url: `${API_ADDRESS}/file-storing/${fileCloud}/delete`,
            data: { fileId },
            headers: {
                Authorization: this.authContext.access_token,
            }
        })
        return remove;
    }
    async getFiles() {
        let data = await axios({
            method: 'get',
            url: `${API_ADDRESS}/file-storing/get-files`,
            headers: {
                Authorization: this.authContext.access_token,
                "Content-Type": "multipart/form-data"
            }
        })
        return data;
    }
    async getFileDetail(location) {
        let data = await axios({
            method: 'get',
            url: `${API_ADDRESS}${location}`,
        })
        return data;
    }
    async updateFile(fileId, fileName, fileCloud) {
        let update = await axios({
            method: 'put',
            url: `${API_ADDRESS}/file-storing/${fileCloud}/edit`,
            data: { fileId, fileName },
            headers: {
                Authorization: this.authContext.access_token,
                "Content-Type": "multipart/form-data"
            }
        })
        return update;
    }
    async editFile(fileId, fileName, fileCloud) {
        let nameOfFile = '';
        if ((fileName.split('.')).length > 1) {
            nameOfFile = (fileName.split('.'))[0]
            for (let i = 1; i < (fileName.split('.')).length - 1; i++)
                nameOfFile += `.${(fileName.split('.'))[i]}`

        }
        else nameOfFile = fileName
        const { value: fileNameInput } = await Swal.fire({
            title: 'Edit file',
            input: 'text',
            inputLabel: 'Update your file name:',
            inputValue: nameOfFile,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to input your new file name!'
                }
            }
        })

        if (fileNameInput) {
            let update = this.updateFile(
                fileId,
                `${fileNameInput}${(fileName.split('.')).length > 1 ? `.${(fileName.split('.'))[(fileName.split('.')).length - 1]}` : ''}`,
                fileCloud)
            return update
        } else
            return null
    }
}
export default FileFunction;


