import { createContext } from 'react';
export const AuthContext = createContext({
    isLoggedIn: false,
    access_token: null,
    userId: null,
    username: null,
    userimage: null,
    showSideBar: true,
    setLogin: () => { },
    setShowSideBar: () => { }
});