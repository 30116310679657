import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { API_ADDRESS } from "../../lib/variants";
const Message = ({ props }) => {
    return (
        <div className={props.isSendMessage ? 'chat' : 'chat chat-left'}>
            <div className="chat-body">
                <div className="chat-message">
                    {!props.isSendMessage ?
                        <>
                            <div className="ms-1">
                                <div className="avatar avatar-ml me-3 user-avt">
                                    <Image src={`${API_ADDRESS}/user/get-avatar/${props.username}`} />
                                </div>
                                <h6 className="font-bold">@{props.username}</h6>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {
                        props.messageType === 'text' ?
                            <p>
                                {props.messageContent}
                            </p>
                            :
                            <>
                                <h6 style={{ color: 'white' }}>
                                    [FILE]
                                    <Link style={{ color: 'white' }} to={`./../file-storing/file/${JSON.parse(props.messageContent).fileId}`}>
                                        {JSON.parse(props.messageContent).fileName}
                                    </Link>

                                </h6>
                            </>

                    }
                    <hr />
                    <span className='chat-message-time'>{props.messageTime}</span>
                </div>
            </div>
        </div>
    )

}
export default Message;