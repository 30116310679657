import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FileFunction from "../../lib/FileFunction";
import MessageFunction from "../../lib/MessageFunction";
import { toast } from 'react-toastify';
const FileMessageAttach = ({ props }) => {
    const fileFunction = new FileFunction()
    const messageFunction = new MessageFunction()
    const [isGotFiles, setIsGotFiles] = useState(false)
    const [fileList, setFileList] = useState([])
    useEffect(() => {
        fileFunction.getFiles().then((data) => {
            if (data.data.success) {
                setIsGotFiles(true)
                Array.from(data.data.message).forEach((file) => {
                    setFileList(data.data.message)
                })

            }
        })
    }, [isGotFiles])
    if (props.showFileMessageAttach)
        return (
            <>
                <div className="modal fade text-left w-100 show" tabIndex={-1} role="dialog" aria-hidden="false" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-full"
                        role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-primary">
                                <h4 className="modal-title white">File Browser
                                </h4>
                            </div>
                            <div className="modal-body">
                                {
                                    fileList.length > 0 ?
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-responsive table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" width={'65%'}>NAME</th>
                                                        <th className="text-center" width={'5%'}>SIZE</th>
                                                        <th className="text-center" width={'5%'}>SEND</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {


                                                        fileList.map((file) => {
                                                            return (
                                                                <tr key={file.fileId}>
                                                                    <td className="text-bold-500">{file.fileName}</td>
                                                                    <td className="text-center text-bold-500">{fileFunction.formatBytes(file.fileSize)}</td>
                                                                    <td className="text-center"><Button onClick={
                                                                        () => {
                                                                            messageFunction.sendFile(file)
                                                                            toast.success(`File was sent!`, {
                                                                                position: toast.POSITION.TOP_RIGHT,
                                                                                autoClose: 3000
                                                                            });
                                                                        }}>Send</Button></td>
                                                                </tr>
                                                            )
                                                        })

                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <>
                                            Your don't have any file is storing to share, please upload some files before !
                                        </>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light-secondary" onClick={() => props.setShowFileMessageAttach(false)}
                                    data-bs-dismiss="modal">
                                    <i className="bx bx-x d-block d-sm-none"></i>
                                    <span className="d-none d-sm-block">Close</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    else return (
        <>
        </>
    )
}
export default FileMessageAttach;