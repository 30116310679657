import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Form, Button } from "react-bootstrap";
import MenuBar from "../menu-bar/MenuBar";
import { AuthContext } from "../../context/Context";
import { API_ADDRESS } from "../../lib/variants";
import { ToastContainer, toast } from 'react-toastify';
import UserFunction from "../../lib/UserFunction";
const ProfilePage = () => {
    const authContext = useContext(AuthContext);
    const userFunction = new UserFunction()
    const [userImage, setUserImage] = useState(`${API_ADDRESS}/user/get-avatar/${authContext.username}`);
    const [newPassword, setNewPassword] = useState('');
    const [imageUpdateCouter,setImageUpdateCouter] = useState(0);
    const updateAvt = async (imageSelected) => {
        try {
            await userFunction.updateAvatar(imageSelected).then((data) => {
                if (data.data.success) {
                    setImageUpdateCouter(imageUpdateCouter+1)
                    setUserImage(`${API_ADDRESS}/user/get-avatar/updated/${imageUpdateCouter}/${authContext.username}`)
                    toast.success(data.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                }
                else
                    toast.error(data.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
            })
        } catch (err) {
            console.log(err)
        }
    }
    const updatePassword = async () => {
        if (newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/) != null) {
            try {
                await userFunction.updatePassword(newPassword).then((data) => {
                    setNewPassword('')
                    if (data.data.success) {
                        toast.success(data.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                    }
                    else
                        toast.error(data.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                })
            } catch (err) {
                console.log(err)
            }
        } else {
            toast.error('New password is not valid!', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
    }
    return (
        <>
            <MenuBar />
            <div className="main">
                <header className="mb-3">
                    <Link to="#" className="burger-btn d-block d-xl-none" onClick={() => authContext.setShowSideBar(true)}>
                        <i className="bi bi-justify fs-3"></i>
                    </Link>
                </header>
                <div className="page-heading">
                    <div className="page-title">
                        <Row>
                            <div className="col-12 col-md-6 order-md-1 order-last">
                                <h3>Profile @{authContext.username}</h3>
                            </div>
                        </Row>
                    </div>
                    <section>
                        <ToastContainer />
                        <Row className="match-height">
                            <Col md={12}>
                                <Card>
                                    <Card.Body className="py-4 px-5">
                                        <div className="col-md-12 col-12">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar avatar-xl">
                                                    <img src={userImage} alt={`Avatar of ${authContext.username}`} />
                                                </div>
                                                <div className="ms-3 name">
                                                    <h6 className="text-muted mb-0">@{authContext.username}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divider">
                                            <div className="divider-text">Change avatar</div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <Form.Group>
                                                <Form.Control type='file' accept='.png, .jpeg, .jpg' onChange={(e) => { updateAvt(e.target.files[0]) }} />
                                            </Form.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <div className="card-content">
                                        <Card.Body>
                                            <Row>
                                                <div className="col-md-12 col-12">
                                                    <Form.Group>
                                                        <Form.Control type="password" placeholder="New password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                                    </Form.Group>
                                                </div>
                                                <div className="divider">
                                                    <div className="divider-text"></div>
                                                </div>
                                                <div className="col-md-12 col-12">
                                                    <Button className="btn-block btn-primary" onClick={updatePassword}>Change password</Button>
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div >
        </>
    );
}
export default ProfilePage;